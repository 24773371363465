import React, { Component } from "react";
// import Fade from "react-reveal";
import Carousel from "react-multi-carousel";
import coverOne_graf from "../assets/TradeWebsite/Capture4.jpg"
import coverOne_graf_two from "../assets/TradeWebsite/Capture1.jpg"
import coverOne_exceptional from "../assets/TradeWebsite/Capture2.jpg"
import coverOne_exceptional_two from "../assets/TradeWebsite/Capture3.jpg"
import Innovative from "../assets/TradeWebsite/Capture5.jpg"

import "react-multi-carousel/lib/styles.css";


export class DezininCarosol extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    const captionStyle = {
      fontSize: "2em",
      fontWeight: "bold",
    };
    const slideNumberStyle = {
      fontSize: "20px",
      fontWeight: "bold",
    };
    return (
      <section id="collection" className="collection_carosol">
        {/* <Fade  distance="40px"> */}
        <div className="row">
          <div style={{ textAlign: "center" }}>
            {/* <h2>React Carousel Minimal</h2>
          <p>Easy to use, responsive and customizable carousel component for React Projects.</p> */}
            <div
            // style={{
            //   padding: "0 20px",
            // }}
            >
              <Carousel swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">
                <div className="carosel_part">
                  <img src={coverOne_graf} />
                  <p className="legend">Crafting a Greener
                      <br/>
                      Tomorrow</p>
                </div>
                <div className="carosel_part">
                  <img src={coverOne_graf_two} />
                  <p className="legend">Crafting a Greener
                      <br/>
                      Tomorrow</p>
                </div>
                <div className="carosel_part">
                  <img src={Innovative} />
                  <p className="legend"> Innovative Design
                      <br/>
                      Transforms Production Landscape!</p>
                </div>
                <div className="carosel_part">
                  <img src={coverOne_exceptional} />
                  <p className="legend"> Exceptional speed
                      <br/>
                      and excellence</p>
                </div>
                <div className="carosel_part">
                  <img src={coverOne_exceptional_two} />
                  <p className="legend"> Exceptional speed
                      <br/>
                      and excellence</p>
                </div>
              </Carousel>

              {/* <Carousel infiniteLoop={true}>
                <div>
                    <img src={coverOne} />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={coverTwo} />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src={WomenEmpower}/>
                    <p className="legend">Legend 3</p>
                </div>
            </Carousel> */}
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </section>
    );
  }
}

export default DezininCarosol;
