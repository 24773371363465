import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navigation from "./components/navigation";
 import Footer from "./components/footer";
import { BrowserRouter, Route, Routes  } from "react-router-dom";
import Home from './components/home';
import Brand from './components/brand';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={"/"}>
  <React.StrictMode>
  <Navigation />
  <Routes >
  <Route exact path="/" element={<Home/>}/>
  <Route exact path="/brand" element={<Brand/>}/>

</Routes >
  {/* <Footer/>         */}

  </React.StrictMode>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
