import React, { Component } from 'react'
import Zmage from "react-zmage";
import Fade from "react-reveal";
export class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            department_name: "",
            foo: "bar",
            resumeData: {}
      
          };
        
    }
  render() {
    return (
        <div  className="columns portfolio-item">
        <div className="item-wrap">
          <Zmage alt={this.props.title} src={this.props.projectImage} />
          {/* <div style={{ textAlign: "center" }}>{projects.title}</div> */}
        </div>
      </div>
    )
  }
}

export default Collection