import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import Collections from "./Collections"
class Brand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            department_name: "",
            foo: "bar",
            resumeData: {}
      
          };
          ReactGA.initialize("UA-110570651-1");
          ReactGA.pageview(window.location.pathname);
          this.getResumeData = this.getResumeData.bind(this)
    }
    getResumeData() {
        $.ajax({
          url: "./resumeData.json",
          dataType: "json",
          cache: false,
          success: function(data) {
            this.setState({ resumeData: data });
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
            alert(err);
          }
        });
      }
    
      componentDidMount() {
        this.getResumeData();
      }
    render() { 
        console.log("resumeData",this.state.resumeData)
        return (  
            <div>
              <Collections title={"Shop By Category"} data={this.state.resumeData.collection} />
            </div>
        );
    }
}
 
export default Brand