import React, { Component } from 'react'
import Zmage from "react-zmage";
import Fade from "react-reveal";
import projectImage4 from "../assets/TradeWebsite/Capture4.jpg"
import projectImage1 from "../assets/TradeWebsite/Capture1.jpg"
import projectImage2 from "../assets/TradeWebsite/Capture2.jpg"
import projectImage3 from "../assets/TradeWebsite/Capture3.jpg"
import projectImage5 from "../assets/TradeWebsite/Capture5.jpg"
import Collection from './Collection';
export class Collections extends Component {
  render() {
    return (
      <section id="collection">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1 className="uppercase_css">Brands</h1>

            <div
              id="collection-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
            <Collection title="Title1" projectImage={projectImage1}/>
            <Collection title="Title1" projectImage={projectImage2}/>
            <Collection title="Title1" projectImage={projectImage3}/>
            <Collection title="Title1" projectImage={projectImage4}/>
            <Collection title="Title1" projectImage={projectImage5}/>
            </div>
          </div>
        </div>
      </Fade>
    </section>
    )
  }
}

export default Collections