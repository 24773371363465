import React, { Component, Fragment } from "react";
import DezninCarosol from "./DezininCarosol"

export class home extends Component {
  render() {
    return (
     <div className="home_comp_main">
      <DezninCarosol/>

     </div>
    );
  }
}

export default home;
